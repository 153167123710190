<template>
  <list-container-widget
    id="grants"
    :title="widgetName"
    :app-id="38"
    stretch
    class="d-flex"
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="grants" />
    </template>

    <!-- Container with data -->
    <div v-if="items && items.length > 0">
      <div ref="container" class="x-scroll-container w-100" @scroll="onScroll">
        <!-- Items Loop -->
        <b-card
          v-for="(item, index) in items"
          :key="index"
          no-body
          class="x-scroll-element mx-1 mb-3 p-0 card-standard"
        >
          <b-link :to="{ name: 'grant', params: { id: item.key } }">
            <!-- Image -->
            <b-card-body class="pb-2 d-block">
              <!-- Product Details -->
              <h4 class="mt-2 text-ellipsis-lines-2">
                {{ item.name }}
              </h4>
              <b-card-text v-if="item.budget && item.budget > 0" class="min-height-1 w-100 ">
                <h5 class="font-weight-bold">
                  {{ $t('form-create-item.budget') }}: {{ item.budget }}€
                  <!-- {{ item.currency.symbol }} -->
                </h5>
              </b-card-text>
              <div v-else class="min-height-1 mt-1" />
            </b-card-body>
          </b-link>
        </b-card>
        <!-- Buttons prev and next -->
        <button class="link-previous btn btn-icon btn-primary btn-previous" @click="scrollOnePage(-1)">
          <b-spinner small class="spinner" />
        </button>
        <button
          class="link-next btn btn-icon btn-primary btn-next"
          :class="{ 'btn-next--loading': isLoadingNextPage }"
          @click="scrollOnePage(+1)"
        >
          <b-spinner small class="spinner" />
        </button>
      </div>
    </div>

    <!-- Loading -->
    <div v-else-if="isLoading">
      <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
    </div>

    <!-- Container without data: Placeholder -->
    <b-row v-else class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="grantsPlaceholder">
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t('available.message', { itemName: widgetName }) }}
        </p>
      </b-col>
    </b-row>
    
    <description-addon v-model="isAppInfoModalVisible" :app-id="3" />
  </list-container-widget>
</template>

<script>
// import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { getImageResource } from '@/@core/utils/image-utils';
// import { quillEditor } from 'vue-quill-editor';
import DescriptionAddon from '@/@core/widgets/DescriptionAddon.vue';
// import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
// import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import grantsPlaceholder from '@/assets/images/placeholders/light/grant.svg';
// import vSelect from 'vue-select';
// import { checkPermissions } from '@/@core/utils/roles-utils';

export default {
  name: 'GrantsListWidget',
  components: {
    // quillEditor,
    DescriptionAddon,
    // WidgetActions,
    ListContainerWidget,
    // vSelect,
    // ToastificationContentVue,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: false,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      grantInput: {},
      formError: null,
      itemImage: null,
      imageSrc: null,
      isAppInfoModalVisible: false,
      searchInput: '',
      results: [],
    };
  },
  computed: {
    // Load data from store
    itemsData() {
      return this.$store.getters.grants;
    },
    items() {
      const itemsToIterate = this.searchInput ? this.results : this.itemsData.unpaginated;
      return itemsToIterate;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    grantsPlaceholder() {
      return grantsPlaceholder;
    },
    itemsLength() {
      return this.items ? this.items.length : 0;
    },
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData(force = false) {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'grants',
        page: this.lastLoadedPage,
        forceAPICall: force,
        requestConfig: {
          count: 16,
          orderByDate: -1,
        },
      });
      this.isLoading = false;
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd = event.srcElement.scrollWidth - event.srcElement.scrollLeft - event.srcElement.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      } else {
        this.isLoadingNextPage = false;
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      const searchTermToLower = searchTerm.toLowerCase();
      this.results = this.itemsData.unpaginated.filter((item) => {
        const itemNameStr = JSON.stringify(item.name).toLowerCase();
        if(itemNameStr.includes(searchTermToLower)){
          return item;
        }
      });
    },
  },
};
</script>
